import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';


let currentDate = moment().format('MM/DD/yyyy, h:mm:ss a');

//className="btn btn-primary"  href="https://wa.me/+56931081928"
       
        

const blogData = [
  {
    id: 1,
    image: require('../assets/images/PowerPlstform.jpeg'),
    referencia:"https://wa.me/+56931081928",
    time:currentDate,
    title: 'Apps power platform',
    tarjeta:'_blank',
    description: 'Tenemos experiencia en el desarrollo de aplicaciones utilizando la suite Power Platform, que incluye Power Apps, Power Automate, Power BI y Ex Virtual Agent Copilot Studio.',
    link: 'https://www.google.com'
  },
  {
    id: 2,
    image: require('../assets/images/MicrosoftNet.jpg'),
    referencia:"https://wa.me/+56931081928",
    time:  currentDate,
    title: 'Apps .Net Core',
    tarjeta:'_blank',
    description: 'Tenemos experiencia en el desarrollo de aplicaciones utilizando tecnologías como .NET Core para la creación de API REST y Blazor para interfaces de usuario web interactivas.',
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/images/ReactOpencode.jpg'),
    referencia:"https://wa.me/+56931081928",
    time: currentDate,
    title: 'Apps open code',
    tarjeta:'_blank',
    description: 'Tenemos experiencia en el desarrollo de aplicaciones de código abierto, utilizando tecnologías populares como React, WordPress y SPFx.',
    link: 'https://www.twitter.com'
  }
]

export default function AppBlog() {
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nuestra experiencia</h2>
          <div className="subtitle">Tenemos competencia en una amplia variedad de tecnologías.</div>
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={4} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <time>{blog.time}</time>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <a href={blog.referencia} target={blog.tarjeta} className="btn btn-primary">Contactar&nbsp;&nbsp;<MDBIcon color='light' icon='phone' className='me-2' /></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

