import Carousel from 'react-bootstrap/Carousel';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

var heroData = [
  {
    id: 1,
    image: require('../assets/images/PowerPlatform365.jpg'),
    title: 'Microsoft Power Platform',
    description: 'Somos líderes en el mundo de la tecnología Power Platform, dedicados a desarrollar aplicaciones innovadoras y efectivas para empresas de diversos sectores. Nuestro compromiso es brindar soluciones personalizadas que impulsen el crecimiento y la eficiencia de tu negocio. Confía en nuestra experiencia y expertise para llevar tu empresa al siguiente nivel tecnológico.',
    link: 'https://www.google.com'
  },
  {
    id: 2,
    image: require('../assets/images/sharepoint365_.jpg'),
    title: 'Sharepoint 365',
    description: "¿Buscas una solución para ordenar y proteger tus documentos internos de manera efectiva? En nuestro equipo, nos especializamos en la creación, migración e implementación de gestores documentales, llevando tus datos desde entornos locales de Microsoft On-Premise hacia la versatilidad y seguridad de Microsoft SharePoint 365.",
    link: 'https://www.facebook.com'
  },
  {
    id: 3,
    image: require('../assets/images/imagecore.jpg'),
    title: 'Web Api / Blazor',
    description: 'Nosotros creamos una sinergia excepcional al combinar tecnologías como Web API, Blazor MVC y el poderoso lenguaje de programación C# en el entorno de desarrollo .NET Core. Esta combinación abre un abanico de posibilidades para la implementación de soluciones robustas y escalables.',
    link: 'https://www.twitter.com'
  },
  {
    id: 4,
    image: require('../assets/images/Sqlserver.jpg'),
    title: 'Motores de BD',
    description: 'Ya sea en entornos SQL Server, Azure SQL, MySQL  u Oracle, estamos capacitados para optimizar y gestionar eficientemente los flujos de datos, garantizando un rendimiento óptimo y una integridad sólida en nuestras soluciones.',
    link: 'https://www.twitter.com'
  }
  
  

]

export default function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p>{hero.description}</p>
                    <a className="btn btn-primary"  href="https://wa.me/+56931081928"
       
        target="_blank"
        >contactar &nbsp;&nbsp;<MDBIcon color='light' icon='phone' className='me-2' /></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

