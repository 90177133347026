import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    link: 'https://www.koandina.com/',
    target:"_blank",
    image: require('../assets/images/bandina.jpg'),
    title: 'Andina',
    subtitle: "Creación de software en Power Platform."
  },
  {
    id: 2,
    link: 'https://tinguiriricaenergia.cl/',
    target:"_blank",
    image: require('../assets/images/Tenergia.jpg'),
    title: 'Tenergia',
    subtitle: 'Customización de Bibliotecas de documentos Sharepoint 365.'
  },
  {
    id: 3,
    link: 'https://arauco.com/',
    target:"_blank",
    image: require('../assets/images/Celulosaarauco.jpg'),
    title: 'Celulosa arauco',
    subtitle: 'Customización y personalización de Sharepoint 2010 y 2013.'
  },
  {
    id: 4,
    link: 'https://www.ust.cl/',
    target:"_blank",
    image: require('../assets/images/Universidadsantothomas.jpg'),
    title: 'Universidad Santo Tomás',
    subtitle: 'Mantención de sitios asp, asp.net y sharepoint 2010.'
  },
  {
    id: 5,
    link: 'https://www.caserones.cl/',
    target:"_blank",
    image: require('../assets/images/Luminacooper.jpg'),
    title: 'Caserones',
    subtitle: 'Personalización SPFx en Paginas Sharepoint 365.'
  },
  {
    id: 6,
    link: 'https://www.multi-xsalmon.com/en/',
    target:"_blank",
    image: require('../assets/images/Multix.jpg'),
    title: 'Multi-x',
    subtitle: 'Implementación de sharepoint 365, y Power Automate'
  },
  {
    id: 7,
    link: 'https://portales.bancochile.cl/',
    target:"_blank",
    image: require('../assets/images/Bancodechile.jpg'),
    title: 'Banco de Chile TI',
    subtitle: 'Apis, SPfx, Mejoras en sitios de Sharepoint 365'
  },
  {
    id: 8,
    link: 'https://www.silicanetworks.com/es/',
    target:"_blank",
    image: require('../assets/images/Silicanetwork.jpg'),
    title: 'Silica BI',
    subtitle: 'Implementación de Power BI, dinamico para clientes.'
  },
  {
    id: 9,
    link: 'https://itprove.com.mx/',
    target:"_blank",
    image: require('../assets/images/itprovemx.jpg'),
    title: 'IT Prove',
    subtitle: 'Capacitación en AI Builder a clientes de Mexico.'
  }
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

export default function AppWorks() {
  return (
    <section id="works" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Clientes</h2>
          <div className="subtitle">Nuestra distinguida clientela, a quienes brindamos nuestros servicios con dedicación y excelencia</div>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div  className='portfolio-wrapper shadow-lg p-3 mb-5 bg-white rounded'>
                    <a href={works.link} target={works.target}>
                      <Image   src={works.image} />
                      <div className='label text-center'>
                        <h3>{works.title}</h3>
                        <p>{works.subtitle}</p>
                      </div>
                    </a>
                  </div>
                </Col>
              );
            })
          }
        </Row>
        
      </Container>  
    </section>
  );
}

 