import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import img1 from '../assets/images/Trabajosefectuados2023.jpg';

const html = 90;
const responsive = 95;
const photoshop = 98;

export default function AppAbout() {
 

  return (
    <section id="doit" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Quienes somos</h2>
          <div className="subtitle">Clientes atendidos durante el año 2023 y principios de 2024.</div>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p style={{textAlign:"justify"}}>Somos una institución especializada en el análisis, actualización y desarrollo de software de alta calidad. Durante el período de 2023 y principios de 2024, hemos colaborado con una amplia gama de clientes en sectores tan variados como el gubernamental, retail, minería, pesca y banca. Nuestra experiencia nos ha permitido implementar diversas tecnologías para satisfacer las necesidades específicas de cada cliente y sector de manera efectiva.</p>
            <p style={{textAlign:"justify"}}>Adaptamos tecnologías a necesidades institucionales variadas, incluyendo comunicación, gestión documental, APIs y Power Platform para diversos clientes.</p>
            <div className='progress-block' style={{height:"140"}} >
              <h4>html / css / javascript / react / spfx </h4>
              <ProgressBar  now={html} label={`${html}%`} />
            </div>
            <div className='progress-block'>
              <h4>sharepoint 365 / power platform / azure / Visual studio / sql </h4>
              <ProgressBar now={responsive} label={`${responsive}%`} />
            </div>
            <div className='progress-block'>
              <h4>Power BI / AI Builder / Open AI /copilo studio</h4>
              <ProgressBar now={photoshop} label={`${photoshop}%`} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

